import React, { useState, useCallback, useRef } from "react";
import { projectId } from "../utils/firebase";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import packageJson from "../../package.json";
import { addFailedLogins } from "../utils/firebaseUtils/firestoreWrites";
import { getBlockedUsersByEmail } from "../utils/firebaseUtils/firestoreSelections";
import {
    getMfaResolver,
    resolveTwoFactorAuth,
    setUpInvisibleRecaptcha,
    signInWithEmailAndPassword,
    verifyPhoneNumber,
} from "../utils/firebaseUtils/authUtils";

function Login() {
    const { t } = useTranslation();
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [otpCode, setOtpCode] = useState("");
    const [verificationId, setVerificationId] = useState("");
    const [mfaResolver, setMfaResolver] = useState({});
    const [appVerifier, setAppVerifier] = useState({});
    const [sentOtpCode, setSentOtpCode] = useState(false);

    const recaptchaWrapperRef = useRef({});

    const handleSigninWithMultiFactor = useCallback(async (resolver) => {
        // If more 2FA methods are supported in the future,
        // change only the chosen method on resolver.hints[chosenIndex]
        const twoFactorAuthPhoneData = resolver.hints[0];

        const phoneInfoOptions = {
            multiFactorHint: twoFactorAuthPhoneData,
            session: resolver.session,
        };

        const appVerifierInstance = setUpInvisibleRecaptcha("invisible-recaptcha");
        const verificationIdString = await verifyPhoneNumber(phoneInfoOptions, appVerifierInstance);

        setAppVerifier(appVerifierInstance);
        setVerificationId(verificationIdString);
        setSentOtpCode(true);
    }, []);

    const errorHandler = useCallback(
        async (error) => {
            if (error.code === "auth/multi-factor-auth-required") {
                const resolver = getMfaResolver(error);

                setMfaResolver(resolver);
                setError("");
                await handleSigninWithMultiFactor(resolver);
            } else {
                setError(error.message);

                addFailedLogins({
                    email: username,
                    createdAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
                });
            }

            setLoading(false);
        },
        [username, handleSigninWithMultiFactor]
    );

    const successHandler = useCallback(
        (user) => {
            history.push("/dashboard");
        },
        [history]
    );

    const signIn = useCallback(() => {
        setLoading(true);
        setError();

        getBlockedUsersByEmail(username).then((snap) => {
            if (snap.empty) {
                signInWithEmailAndPassword(username, password).then(successHandler).catch(errorHandler);
            } else {
                setLoading(false);
                setError(t("login.email_blocked"));
            }
        });
    }, [username, password, successHandler, errorHandler, t]);

    const onKeyDown = useCallback(
        (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
                event.stopPropagation();
                signIn();
            }
        },
        [signIn]
    );

    const clearInvisibleRecaptcha = useCallback((appVerifier) => {
        appVerifier.clear();
        recaptchaWrapperRef.current.innerHTML = '<div id="invisible-recaptcha"></div>';
    }, []);

    const verifyOtpAndSignIn = useCallback(async () => {
        setLoading(true);

        try {
            await resolveTwoFactorAuth(verificationId, otpCode, mfaResolver);
            clearInvisibleRecaptcha(appVerifier);
            successHandler();
        } catch (error) {
            clearInvisibleRecaptcha(appVerifier);

            const appVerifierInstance = setUpInvisibleRecaptcha("invisible-recaptcha");
            setAppVerifier(appVerifierInstance);

            setLoading(false);
            setError(t("login.wrong_otp"));
        }
    }, [appVerifier, clearInvisibleRecaptcha, mfaResolver, otpCode, successHandler, t, verificationId]);

    return (
        <section className="bg-home d-flex align-items-center">
            <div ref={recaptchaWrapperRef}>
                <div id="invisible-recaptcha" />
            </div>

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-6">
                        <div className="mr-lg-5">
                            <img src="/images/user/login.png" className="img-fluid d-block mx-auto" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="card login-page bg-white shadow rounded border-0">
                            <div className="card-body">
                                <h4 className="card-title text-center">{t("login.title")}</h4>

                                <form className="login-form mt-4">
                                    <div className="row">
                                        {!sentOtpCode ? (
                                            <>
                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>
                                                            {t("login.email")} <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Username"
                                                            name="username"
                                                            autoComplete="username"
                                                            required=""
                                                            onKeyDown={(e) => onKeyDown(e)}
                                                            onChange={(e) => {
                                                                setUsername(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>
                                                            {t("login.password")} <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Password"
                                                            required=""
                                                            autoComplete="current-password"
                                                            onKeyDown={(e) => onKeyDown(e)}
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div id="recaptcha-container"></div>
                                                </div>

                                                {error && (
                                                    <div className="col-lg-12 mb-0">
                                                        <div className="alert alert-danger" role="alert">
                                                            {error}
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="col-lg-12 mb-0">
                                                    <button
                                                        type="button"
                                                        disabled={loading}
                                                        onClick={signIn}
                                                        className="btn btn-primary btn-block"
                                                    >
                                                        {loading ? t("login.please_wait") : t("login.signin")}
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>
                                                            {t("login.enter_otp")}{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Verification code"
                                                            name="verificationCode"
                                                            required=""
                                                            onChange={(e) => setOtpCode(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mb-0">
                                                    <button
                                                        type="button"
                                                        disabled={loading}
                                                        onClick={verifyOtpAndSignIn}
                                                        className="btn btn-primary btn-block"
                                                    >
                                                        {loading ? t("login.please_wait") : t("login.verify_otp")}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                        <div className="col-lg-12 mt-4 text-center">
                                            {packageJson.version} - {projectId}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;
