/* eslint-disable */
import React, { useEffect, useState } from "react";
import WithTemplate from "./WithTemplate";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import "./assets/css/angel.css";
import audio from "./assets/audio/ring.mp3";
import { onlinePing, changeBusyState } from "../utils/User";
import { get } from "lodash";
import { Modal } from "react-bootstrap";
import { getAuthUser } from "../utils/firebaseUtils/authUtils";
import { getHospital, subscribeUserCollection } from "../utils/firebaseUtils/firestoreSelections";
import { addHospitalSessionsListener, removeSession, unsubscribeHospitalsListener } from "../utils/firebaseUtils/realtimeWrites";

function Dashboard() {
    const { t } = useTranslation();
    const [user, setUser] = useState();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [calls, setCalls] = useState([]);
    const [ring, setRing] = useState(false);
    const [newCall, setNewCall] = useState(0);
    const [listNewCalls, setListNewCalls] = useState([]);
    const [buddyEnabled, setBuddyEnabled] = useState();

    const acceptCall = (call) => {
        history.push(`/call/${call.data.hospitalId}/${call.data.deviceId}/${call.data.sessionId}`);
    };

    useEffect(() => {
        if (!user || buddyEnabled === undefined) return;
        //This is necessary, cause IOS Safari does not supports Notification API
        if (typeof Notification !== "undefined") {
            Notification.requestPermission();
        }

        if (!buddyEnabled) {
            console.log("Buddy Disabled!");
            setLoading(false);
            return;
        }

        console.log("Buddy available!");

        changeBusyState(user.hospital, user.id, false);
        let onlinePingInterval = onlinePing(user.hospital, user.id);
        addHospitalSessionsListener(user.hospital, (snapshot) => {
            const tempCalls = [];
            if (snapshot) {
                snapshot.forEach(function (childSnapshot) {
                    const date = new Date(moment.utc(childSnapshot.val().datetime).local());
                    const today = new Date();
                    const diff = Math.round((today - date) / 1000 / 60);
                    if (diff <= 15)
                        tempCalls.push({
                            id: childSnapshot.key,
                            data: childSnapshot.val(),
                        });
                });
            }

            setCalls(tempCalls);
            setLoading(false);
        });

        return () => {
            setNewCall(0);
            unsubscribeHospitalsListener(user.hospital);
            clearInterval(onlinePingInterval);
        };
    }, [user, buddyEnabled]);

    useEffect(() => {
        calls.forEach((call) => {
            const diff = moment().diff(moment.utc(call.data.datetime).local(), "minutes");
            if (diff > 10) {
                console.log("Removing session because it is too old", call.data);
                removeSession(call.data.hospitalId, call.data.deviceId).catch((err) => {
                    console.error("Could not remove session from realtime", err);
                });
            }
        });

        let timeout;
        if (calls.filter((i) => !listNewCalls.map((r) => r.id).includes(i.id)).length > 0) {
            setListNewCalls(calls);
            setNewCall(calls.length);
            setRing(true);
            timeout = setTimeout(() => setRing(false), 3000);
        } else if (listNewCalls.filter((i) => !calls.map((r) => r.id).includes(i.id))) {
            setListNewCalls(calls);
        }

        return () => {
            clearTimeout(timeout);
            setRing(false);
        };
    }, [calls.length]);

    useEffect(() => {
        if (!newCall) {
            return;
        }

        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            var notification = new Notification(t("dashboard.new_call_notification"));
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    var notification = new Notification(t("dashboard.new_call_notification"));
                }
            });
        }
    }, [newCall]);

    useEffect(() => {
        if (!get(user, "hospital")) return;

        getHospital(user.hospital).then((doc) => {
            setBuddyEnabled(get(doc.data(), "isBuddyEnabled", true));
        });
    }, [user]);

    useEffect(() => {
        const user = getAuthUser();
        const unsubscribeUser = subscribeUserCollection(user.uid, (doc) => {
            setUser({ id: doc.id, ...doc.data() });
        });

        return () => unsubscribeUser();
    }, []);

    return (
        <WithTemplate>
            <section className="bg-half-100 d-table w-100" style={{ fontFamily: "RobotoCondensed" }}>
                <div className="container mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title mb-4 pb-2">
                                <h4 className="title mb-4">{t("dashboard.incoming_calls")}</h4>
                            </div>
                        </div>
                    </div>
                </div>

                {buddyEnabled !== undefined && !buddyEnabled && (
                    <Modal show={true} centered={true}>
                        <Modal.Header closeButton={false}>
                            <Modal.Title>{`${t("dashboard.sorry")} ${get(user, "name", "")} ${t(
                                "dashboard.buddy_disabled_for_hospital"
                            )}`}</Modal.Title>
                        </Modal.Header>
                    </Modal>
                )}

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="table-responsive crypto-table bg-white shadow rounded">
                                <table className="table mb-0 table-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{t("dashboard.type")}</th>
                                            <th scope="col">{t("dashboard.device")}</th>
                                            <th scope="col">{t("dashboard.requested_at")}</th>
                                            <th scope="col">{t("dashboard.buddy")}</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading && (
                                            <tr>
                                                <td>{t("dashboard.loading_calls")}</td>
                                            </tr>
                                        )}
                                        {!loading && calls.length === 0 && (
                                            <tr>
                                                <td>
                                                    {buddyEnabled
                                                        ? t("dashboard.no_calls")
                                                        : t("dashboard.buddy_disabled")}
                                                </td>
                                            </tr>
                                        )}
                                        {calls
                                            .sort((call) => (call.data.signedToId ? 1 : -1))
                                            .map((call, index) => (
                                                <tr key={call.id}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {call.data.type === "DON"
                                                            ? t("dashboard.donning")
                                                            : t("dashboard.doffing")}
                                                    </td>
                                                    <td>{call.data.deviceAlias}</td>
                                                    <td>{moment.utc(call.data.datetime).local().format("HH:mm:ss")}</td>
                                                    <td>{call.data.signedTo ? call.data.signedTo : ""}</td>
                                                    <td style={{ padding: "0" }}>
                                                        <a
                                                            className={`connection__button ${
                                                                call.data.signedToId ? "red" : "green"
                                                            }`}
                                                            onClick={() => acceptCall(call)}
                                                        >
                                                            {t("dashboard.answer")}
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                {ring && newCall && <ReactAudioPlayer src={audio} autoPlay loop />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </WithTemplate>
    );
}

export default Dashboard;
