import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getPerformance } from "firebase/performance";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const REGIONS = {
  "buddy.bluemirror.ai": "australia-southeast1",
  "buddy-uk.bluemirror.ai": "europe-west2",
  "buddy-sg.bluemirror.ai": "asia-southeast1",
};

const HOST_NAME = window.location.hostname;
const FUNCTIONS_REGION = REGIONS[HOST_NAME] || "asia-east2";

const GLOBAL_PROD = {
  apiKey: "AIzaSyA3MjInoEHbX6_9QLPod5OWMvQG7rmPEH8",
  authDomain: "bluemirror.firebaseapp.com",
  databaseURL: "https://bluemirror.firebaseio.com",
  projectId: "bluemirror",
  storageBucket: "bluemirror.appspot.com",
  messagingSenderId: "874592823861",
  appId: "1:874592823861:web:38fffb6bb568a8e5ec1f7c",
  measurementId: "G-MW5VQSCE50",
};

const SG_PROD = {
  apiKey: "AIzaSyA_PYNIQhW8AnH-l6toJGNT9pWTuUBEIdM",
  authDomain: "bluemirror-sg.firebaseapp.com",
  databaseURL:
    "https://bluemirror-sg-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "bluemirror-sg",
  storageBucket: "bluemirror-sg.appspot.com",
  messagingSenderId: "992214845102",
  appId: "1:992214845102:web:1386cd997800c0a7968f3f",
  measurementId: "G-1CW3CV48CT",
};

const UK_PROD = {
  apiKey: "AIzaSyDVQHrqap6Ier7ngzhe6zF71mSIDTOCUX8",
  authDomain: "bluemirror-uk.firebaseapp.com",
  databaseURL:
    "https://bluemirror-uk-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bluemirror-uk",
  storageBucket: "bluemirror-uk.appspot.com",
  messagingSenderId: "275301204135",
  appId: "1:275301204135:web:1bcae5fc8dd8a3374d5b82",
};

const DEVELOPMENT = {
  apiKey: "AIzaSyBKEn5R59oYERcID79-8S5I9RXA2e2uVQo",
  authDomain: "bluemirror-dev.firebaseapp.com",
  databaseURL: "https://bluemirror-dev-default-rtdb.firebaseio.com",
  projectId: "bluemirror-dev",
  storageBucket: "bluemirror-dev.appspot.com",
  messagingSenderId: "74861103715",
  appId: "1:74861103715:web:0762bd287902eaa41e4656",
  measurementId: "G-4L4T19JESV",
};

const CONFIGS = {
  "buddy.bluemirror.ai": GLOBAL_PROD,
  "buddy-uk.bluemirror.ai": UK_PROD,
  "buddy-sg.bluemirror.ai": SG_PROD,
};

const firebaseConfig = CONFIGS[HOST_NAME] || DEVELOPMENT;

console.debug(`Config loaded for ${firebaseConfig.projectId}`);

export const firebaseImpl = initializeApp(firebaseConfig);
export const projectId = firebaseImpl.options.projectId;

export const appCheck = initializeAppCheck(firebaseImpl, {
  provider: new ReCaptchaV3Provider("6Lc7GbsZAAAAAO1N-nEF7EGoRGa-cMugzHw8xMki"),
  isTokenAutoRefreshEnabled: true,
});

const environments = {
  bluemirror: "PROD",
  "bluemirror-sg": "PROD",
  "bluemirror-uk": "PROD",
  "bluemirror-uat": "UAT",
  "bluemirror-dev": "DEV",
};

export const performance = getPerformance();
export const realtimeDatabase = getDatabase();
export const firebaseAuth = getAuth();
export const firestore = getFirestore();
export const firebaseStorage = getStorage();
export const firebaseFunctions = getFunctions(firebaseImpl, FUNCTIONS_REGION);
export const currentEnviroment = environments[projectId];
