import { query } from "firebase/database";
import { collection, doc, getDoc, getDocs, onSnapshot, where } from "firebase/firestore";
import { firestore } from "../firebase";

export async function getProcedures() {
    const proceduresCollection = collection(firestore, "procedures");
    const proceduresQuery = query(proceduresCollection);
    return getDocs(proceduresQuery);
}

export async function getDevice(deviceId) {
    const deviceRef = doc(firestore, "devices", deviceId);
    return getDoc(deviceRef);
}

export async function getUser(userId) {
    const usersRef = doc(firestore, "users", userId);
    return getDoc(usersRef);
}

export async function getBuddySession(sessionId) {
    const buddySessionsRef = doc(firestore, "buddy_sessions", sessionId)
    return getDoc(buddySessionsRef);
}

export function subscribeUserCollection(userId, callBack) {
    const usersRef = doc(firestore, "users", userId);
    return onSnapshot(usersRef, callBack);
}

export async function getHospital(hospitalId) {
    const hospitalsRef = doc(firestore, "hospitals", hospitalId)
    return getDoc(hospitalsRef);
}

export async function getBlockedUsersByEmail(email) {
    const blockedUsersCollection = collection(firestore, "blockedUsers");
    const blockedUsersQuery = query(blockedUsersCollection, where("email", "==", email));
    return getDocs(blockedUsersQuery);
}