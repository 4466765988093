import React from "react";
import { useTranslation } from "react-i18next";

function CheckingUser() {
  const { t } = useTranslation();

  return (
    <section className="bg-home d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 text-center">
            <div className="text-uppercase mt-4 display-3">
              {t("checking_user.title")}
            </div>
            <div className="text-capitalize text-dark mb-4 error-page">
              {t("checking_user.message")}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CheckingUser;
