/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import '../assets/css/angel.css' // Importing new styles
import moment from "moment-timezone";

import { useTranslation } from "react-i18next";
import { addBuddySessionActions } from "../../utils/firebaseUtils/firestoreWrites";

function Flow({ index, procedure, setCurrentStepHandler, show, commandSender, mirrorSession }) {
  const { t } = useTranslation();
  
  function clickHandler(index) {

    const action = {
      occuredAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
      type: 'SEND_COMMAND',
      button: `${procedure.title.name} -> ${t('call.start')}`,
      action: t('call.send_guide'),
      details: {
        procedure: procedure,
        procedureId: procedure.id
      }
    };

    addBuddySessionActions(mirrorSession, action);

    commandSender(`START|${index}|${procedure.id}`, undefined, true)
  } 

  return (
    <a
      className={`angel__flow__button ${(show) ? "active" : ""}`}
      onClick={() => 
        clickHandler(index)
      } 
    >     
      {procedure.title.name}
      <span className="angel__flow__button__number">{index+1}</span>  
    </a>
  );
}

export default Flow;
