/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import AWS from 'aws-sdk';
import { httpsCallable } from 'firebase/functions';
import { firebaseFunctions } from '../../utils/firebase';

export default function useKinesisDataStream({ deviceId, hospitalId, mirrorSessionId }) {

	const [kinesis, setKinesis] = useState()

	useEffect(() => {
		// get the function to request data
		const getKinesisDataStreamToken = httpsCallable(firebaseFunctions, 'getKinesisDataStreamToken');

		// request data to be able to access WebRTC
		getKinesisDataStreamToken().then(resultKinesisChannel => {

			console.log('getKinesisDataStreamToken', resultKinesisChannel.data.credentials.AccessKeyId);

			const tmpKinesis = new AWS.Kinesis({
				apiVersion: '2013-12-02',
				region: resultKinesisChannel.data.region,
				credentials: {
					accessKeyId: resultKinesisChannel.data.credentials.AccessKeyId,
					secretAccessKey: resultKinesisChannel.data.credentials.SecretAccessKey,
					sessionToken: resultKinesisChannel.data.credentials.SessionToken
				}
			});

			setKinesis(tmpKinesis);

		})

	}, [])

	const sendData = useCallback((imageData, prefix, instructionData) => {

		const recordData = {
			Data: JSON.stringify({
				imageData: imageData,
				deviceId: deviceId,
				hospitalId: hospitalId,
				prefix: prefix,
				sessionId: mirrorSessionId,
				instructionData: instructionData,
				time: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
			}),
			PartitionKey: `partition-${deviceId}`,
		};

		console.log('#modal: ', recordData)

		kinesis.putRecords(
			{
				Records: [recordData],
				StreamName: 'buddy-decisions',
			},
			function (err, data) {
				if (err) {
					console.log('useKinesisDataStream - data', data);
					console.error('useKinesisDataStream - error', err);
				}
			}
		);

	}, [deviceId, hospitalId, mirrorSessionId, kinesis]);

	return [sendData];
}
