/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import logo from "./assets/images/logo.png";
import packageJson from "../../package.json";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { setOffline } from "../utils/User";
import { subscribeUserCollection } from "../utils/firebaseUtils/firestoreSelections";
import { authenticationSignOut, getAuthUser } from "../utils/firebaseUtils/authUtils";

function WithTemplate({ children }) {
    const [user, setUser] = useState();

    const handleLogout = useCallback(() => {
        setOffline(user.hospital, user.id);
        authenticationSignOut();
    }, [user]);

    useEffect(() => {
        if (!user) return;
        i18next.changeLanguage(user.language);
    }, [user]);

    useEffect(() => {
        const user = getAuthUser();
        const unsubscribeUser = subscribeUserCollection(user.uid, (doc) => {
            setUser({ id: doc.id, ...doc.data() });
        });
        return () => unsubscribeUser();
    }, []);

    return (
        <>
            <header className="angel__header">
                <div style={{ display: "flex", justifyContent: "space-between", marginRight: "24px" }}>
                    <Link to="/dashboard">
                        <img className="angel__logo" src={logo} alt="logo" />
                        <span>{packageJson.version}</span>
                    </Link>
                    <div>
                        <button
                            type="button"
                            style={{ marginLeft: "18px", marginRight: "9px" }}
                            className="connection__button blue"
                            onClick={handleLogout}
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </header>

            {children}
        </>
    );
}

export default WithTemplate;
