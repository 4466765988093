/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, Link } from "react-router-dom";
import Flow from "./partials/Flow";
import Steps from "./partials/Steps";
import logo from "./assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp, faVolumeMute, faMicrophone, faMicrophoneSlash, faReply } from "@fortawesome/free-solid-svg-icons";

import { startViewer, sendViewerMessage, stopViewer, getSignalingClient, getViewer } from "./WebRTCViewer";
import packageJson from "../../package.json";
import "./assets/css/angel.css"; // Importing new styles
import { onlinePing, changeBusyState } from "../utils/User";
import ReactAudioPlayer from "react-audio-player";
import ringAudio from "./assets/audio/beep.mp3";
import micAudio from "./assets/audio/mic-sound.wav";
import { Tooltip } from "@material-ui/core";
import useKinesisDataStream from "./hooks/useKinesisDataStream";
import moment from "moment-timezone";
import { getBuddySession, getDevice, getProcedures, getUser } from "../utils/firebaseUtils/firestoreSelections";
import {
    updateBuddySessions,
    addBuddySessionsDecisions,
    setBuddySession,
    addBuddySessionActions,
} from "../utils/firebaseUtils/firestoreWrites";
import { addSessionUpdateDbEvent, removeSession, updateRealTimeDbSession } from "../utils/firebaseUtils/realtimeWrites";
import { getAuthUser } from "../utils/firebaseUtils/authUtils";

const actionTypes = {
    clickButton: "CLICK_BUTTON",
    sendCommand: "SEND_COMMAND",
};

function Call() {
    const videoTag = useRef(null);
    const { t, i18n } = useTranslation();
    const { hospitalId, deviceId, sessionId } = useParams();
    const history = useHistory();

    const [user, setUser] = useState();
    const [userId, setUserId] = useState();

    const [isWebRtcError, setWebRtcError] = useState(false);

    const [procedures, setProcedures] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [modalYesInstruction, setModalYesInstruction] = useState();
    const [modalNoInstruction, setModalNoInstruction] = useState();
    const [revertModalInstruction, setRevertModalInstruction] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [modalTimeout, setModalTimeout] = useState();
    const [isAIRevert, setAIRevert] = useState();
    const [modalIntervalId, setModalIntervalId] = useState();
    const [ring, setRing] = useState(false);
    const [micRing, setMicRing] = useState(false);
    const [counterTimeoutFunc, setCounterTimeoutFunc] = useState();

    const [call, setCall] = useState();
    //const [remoteEvents, setRemoteEvents] = useState([]);
    //const [device, setDevice] = useState({});
    const [steps, setSteps] = useState([]);
    const [callTypeName, setCallTypeName] = useState("");
    //const [callTypeInstructions, setCallTypeInstructions] = useState("");

    const [connectionStatus, setConnectionStatus] = useState("Waiting connection to mirror...");

    const [currentProcedure, setCurrentProcedure] = useState("");
    const [currentStep, setCurrentStep] = useState("");
    //const [audioPlayerStatus, setAudioPlayerStatus] = useState("");
    //const [counterStatus, setCounterStatus] = useState("");

    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [openedProcedure, setOpenedProcedure] = useState();

    const [eventReceived, setEventReceived] = useState();

    const [HH, setHH] = useState(false);
    const [counter, setCounter] = useState(15);

    const [audio, setAudio] = useState(false);

    const [title, setTitle] = useState(false);
    const [step, setStep] = useState(false);
    //const [lastConfirmationProcedure, setLastConfirmationProcedure] = useState();

    const [micStatus, setMicStatus] = useState(false);
    const [mirrorMicMuted, setMirrorMicMuded] = useState(true);

    const [sendKinesisData] = useKinesisDataStream({
        deviceId,
        hospitalId,
        sessionId,
    });

    // Handles procedures updates
    const onProceduresUpdate = useCallback((snapshot) => {
        const tempProc = {};
        snapshot.forEach((procedure) => {
            tempProc[procedure.id] = { id: procedure.id, ...procedure.data() };
        });
        setProcedures(tempProc);
    }, []);

    useEffect(() => {
        getProcedures().then(onProceduresUpdate);
    }, [onProceduresUpdate]);

    useEffect(() => {
        if (HH) {
            console.log("Counting!");
            if (counter > 0)
                setCounterTimeoutFunc(
                    setTimeout(() => {
                        setCounter(counter - 1);
                        if (counter - 1 <= 0) setHH(false);
                    }, 1000)
                );
        }
    }, [HH, counter]);

    useEffect(() => {
        if (HH === false && counter === 0 && counterTimeoutFunc !== undefined) {
            console.log("Cleaning!", counterTimeoutFunc);
            window.clearTimeout(counterTimeoutFunc);
        }
    }, [HH, counter, counterTimeoutFunc]);

    useEffect(() => {
        setOpenedProcedure(currentStepIndex);
    }, [currentStepIndex]);

    function flowHandler(index) {
        if (openedProcedure === index) setOpenedProcedure(currentStepIndex);
        else setOpenedProcedure(index);
    }

    const onGetDevice = useCallback(
        (snapshot) => {
            const isDonning = call && call.type && call.type === "DON";

            if (isDonning) {
                setCallTypeName(t("call.donning"));
            } else {
                setCallTypeName(t("call.doffing"));
            }

            const protocol = snapshot.data().workflows.find((protocol) => protocol.id === call.workflowId);
            setSteps(protocol.procedures);
        },
        [call, t]
    );

    const onRemoteStreamReceived = () => {
        setConnectionStatus("Connected to mirror");
    };

    const onWebRtcError = useCallback((err) => {
        console.error(err);
        setWebRtcError(true);
    }, []);

    const executeKinesis = useCallback(
        ({ ignored, decision, instruction }) => {
            if (!instruction) return;

            console.log("Execute Kinesis: ", ignored, decision, instruction);
            const arrayPayload = instruction.split("|");

            const instructionData = {
                question: arrayPayload[5],
                decision: decision,
                ignored: ignored,
                aiModelType: arrayPayload[0],
                currentProcedureIndex: arrayPayload[1],
                procedureId: arrayPayload[2],
                aiModelNextinstruction: arrayPayload[3],
                currentInstruction: arrayPayload[4],
                fullModalInstruction: instruction,
                procedure: procedures[arrayPayload[2]],
            };

            const video = document.getElementById("mirrorVideo");
            var canvas = document.createElement("canvas");
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            console.log("#KinesisData Executing Kinesis", instructionData);
            const dataToUpdate = {
                occuredAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
                user: user || null,
                ...instructionData,
            };
            addBuddySessionsDecisions(sessionId, dataToUpdate);
            sendKinesisData(canvas.toDataURL(), "buddy-decision", instructionData);
        },
        [user, sendKinesisData, procedures, sessionId]
    );

    const closeModal = useCallback(() => {
        console.log("closeModel - modalIntervalId", modalIntervalId);
        clearInterval(modalIntervalId);
        setModalYesInstruction();
        setModalNoInstruction();
        setRevertModalInstruction();
        setModalTimeout();
        setModalMessage();
        setAIRevert();
        setShowModal(false);
        setRing(false);
    }, [modalIntervalId]);

    const executeModalInstruction = (decision, instruction) => {
        console.log("SENDING", instruction);
        sendCommandToMirror(
            instruction,
            () => {
                closeModal();
            },
            false
        );
        executeKinesis({ ignored: "No", decision, instruction });
    };

    const buddyIgnored = () => {
        sendCommandToMirror(
            modalYesInstruction,
            () => {
                closeModal();
            },
            false
        );
        executeKinesis({
            ignored: "Yes",
            decision: "Yes",
            instruction: modalYesInstruction,
        });
    };

    useEffect(() => {
        if (modalTimeout >= 0) {
            console.log("AI Confirmation modal", modalTimeout);
            if (modalTimeout === 0) {
                if (isAIRevert) {
                    executeKinesis({
                        ignored: "Yes",
                        decision: "Don't revert",
                        instruction: revertModalInstruction,
                    });
                    closeModal();
                } else {
                    buddyIgnored();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalTimeout, isAIRevert, revertModalInstruction]);

    //console.log(lastConfirmationProcedure)

    const typeHandler = useCallback(
        (type) => {
            if (type === "START") {
                setTitle(true);
                setStep(false);
            } else if (type === "GUIDE") {
                setTitle(false);
                setStep(true);
            } else if (type === "SUCCESS" || type === "ERROR") {
                setTitle(false);
                setStep(true);
                clearInterval(modalIntervalId);
                setModalTimeout();
            } else {
                setTitle(false);
                setStep(false);
            }
        },
        [modalIntervalId]
    );

    const [shouldClosePrompt, setClosePrompt] = useState(false);

    useEffect(() => {
        console.log("==========================");
        console.log("shouldClosePrompt", shouldClosePrompt);
        console.log("==========================");
        if (shouldClosePrompt) closeModal();
        setClosePrompt(false);
    }, [shouldClosePrompt, closeModal]);

    useEffect(() => {
        if (!showModal) return;
        setModalIntervalId(
            setInterval(() => {
                setModalTimeout((t) => {
                    return (t ? t : 0) - 1;
                });
            }, 1000)
        );
    }, [showModal]);

    useEffect(() => {
        console.log("==========================");
        console.log("currentStepIndex", currentStepIndex);
        console.log("==========================");
        setClosePrompt(true);
    }, [currentStepIndex]);

    const sendCommandToMirror = useCallback(
        (command, callback, shouldClearConfirmation) => {
            console.log("==> Sending to Mirror", command);
            if (shouldClearConfirmation) {
                setEventReceived();
                //setLastConfirmationProcedure();
            }
            if (command !== undefined && command !== "undefined" && command !== null) {
                setCounter(0);
                sendViewerMessage(command, callback, onWebRtcError);
            } else {
                console.error(`==> Command [${command}] not send, invalid!`);
            }
        },
        [onWebRtcError]
    );

    const removeSessionFromDb = useCallback(
        (hospitalId, deviceId) => {
            removeSession(hospitalId, deviceId)
                .then(() => stopViewer())
                .then(async () => {
                    const dataToUpdate = {
                        buddyLeft: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
                    };
                    await updateBuddySessions(sessionId, dataToUpdate);
                    history.push("/dashboard");
                })
                .catch((err) => {
                    console.error("Could not remove session from realtime", err);
                });
        },
        [history, sessionId]
    );

    const onStopCall = useCallback(() => {
        changeBusyState(hospitalId, userId, false);
        const viewer = getViewer();
        try {
            if (viewer.dataChannel && viewer.dataChannel.readyState === "open") {
                return sendCommandToMirror(
                    "FINISH",
                    () => {
                        removeSessionFromDb(hospitalId, deviceId);
                    },
                    true
                );
            }
            return;
        } catch (error) {
            console.log("Unkown Error");
            console.log(error);
            removeSessionFromDb(hospitalId, deviceId);
        }
    }, [sendCommandToMirror, hospitalId, deviceId, userId, removeSessionFromDb]);

    useEffect(() => {
        if (!eventReceived) return;

        console.log("<== event received:", eventReceived.data);

        const eventItems = eventReceived.data.split("#");
        const feature = eventItems[1];
        const type = eventItems[2];
        const payload = eventItems[3];

        if (feature === "AUDIO" && type === "STATUS" && payload === "start") setAudio(true);
        else setAudio(false);

        //#log-here Bug Counter
        console.log("#modal: Event Item", eventItems);
        if (feature === "COUNTER" && type === "STATUS" && payload === "STARTED") {
            setCounter(15);
            setHH(true);
        } else {
            setHH(false);
        }

        if (feature === "AI" && type === "AI_DECISION") {
            console.log("PAYLOAD", payload);

            const arrayPayload = payload.split("|");

            const type = arrayPayload[0];
            const currentProcedureIndex = arrayPayload[1];
            const procedureId = arrayPayload[2];
            const currentInstruction = arrayPayload[3];
            const aiModelType = arrayPayload[4];
            const aiModelNextinstruction = arrayPayload[5];
            const aiModelVirtualBuddyTimeout = arrayPayload[6];
            const shouldWaitBuddyDecision = arrayPayload[7] === "true" ? true : false;
            const defaultNextInstruction = arrayPayload[8];

            //const aiModel = arrayPayload[7];

            setAIRevert(!shouldWaitBuddyDecision);

            //if(lastConfirmationProcedure !== procedureId){

            setModalTimeout(parseInt(aiModelVirtualBuddyTimeout));
            //setLastConfirmationProcedure(procedureId);
            let question = "";
            if (currentInstruction === "title") {
                setModalMessage(procedures[procedureId][type].aimodel.question);
                question = procedures[procedureId][type].aimodel.question;
            } else {
                setModalMessage(procedures[procedureId][type][currentInstruction].aimodel.question);
                question = procedures[procedureId][type][currentInstruction].aimodel.question;
            }

            setModalYesInstruction(
                `${aiModelType}|${currentProcedureIndex}|${procedureId}|${aiModelNextinstruction}|${currentInstruction}|${question}`
            );
            setModalNoInstruction(
                `${aiModelType}|${currentProcedureIndex}|${procedureId}|${defaultNextInstruction}|${currentInstruction}|${question}`
            );
            setRevertModalInstruction(
                `BI_RECHECK|${currentProcedureIndex}|${procedureId}|${defaultNextInstruction}|${currentInstruction}|${question}`
            );

            setShowModal(true);

            if (shouldWaitBuddyDecision) {
                setRing(true);
                setTimeout(() => setRing(false), 3000);
            }

            //}
        }

        if (feature === "COMMAND_HANDLED" && type === "STATUS") {
            setCounter(0);

            const strToName = {
                S: "success",
                G: "guide",
                E: "error",
            };

            setCurrentStepIndex(parseInt(payload.split("|")[1]));
            //console.log('payload.split("|")[1]', payload.split("|")[1])

            const name = strToName[payload.split("|")[0].split("")[0]];
            const type = name ? name : "title";

            typeHandler(payload.split("|")[0]);
            const exitInstructions = ["FINISH", "FORCE_STOP"];
            if (!exitInstructions.includes(payload.split("|")[0])) {
                const procedure = procedures[payload.split("|")[2]];

                if (procedure) setCurrentProcedure(procedure.title[i18n.language]);
                const currentInstruction = payload.split("|")[3];
                if (procedure[type] && procedure[type][currentInstruction])
                    setCurrentStep(procedure[type][currentInstruction][i18n.language]);
                else setCurrentStep("");
            } else {
                onStopCall();
            }
        }
    }, [typeHandler, eventReceived, t, history, i18n.language, procedures, onStopCall]);

    const callDataHandler = (event) => {
        setEventReceived(event);
    };

    useEffect(() => {
        if (!call) return;
        getDevice(call.deviceId).then(onGetDevice);
    }, [call, onGetDevice]);

    useEffect(() => {
        let timeout;
        console.log("isWebRtcError", isWebRtcError);
        if (isWebRtcError) {
            //stopViewer()
            timeout = setTimeout(() => {
                console.log("Trying to connect to WebRTC again...");
                try {
                    getSignalingClient().open();
                } catch (error) {
                    console.error("Connection error: ");
                    console.error(error);
                }
                setWebRtcError(false);
            }, 3000);
        }

        return () => clearTimeout(timeout);
    }, [isWebRtcError]);

    useEffect(() => {
      const authUser = getAuthUser();
        getUser(authUser.uid).then((doc) => {
            setUser({ id: doc.id, ...doc.data() });
        });
    }, []);

    useEffect(() => {
        if (!user) return;
        setUserId(user.id);
        let onlinePingInterval = onlinePing(user.hospital, user.id, true);

        addSessionUpdateDbEvent(hospitalId, deviceId, (snapshot) => {
            if (!snapshot || snapshot.val() === null) {
                updateBuddySessions(sessionId, {
                    buddyLeft: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
                });
                history.push("/dashboard");
                return;
            }

            getBuddySession(sessionId).then((session) => {
                if (session.data() === undefined) {
                    const { datetime, ...mirrorMetadata } = snapshot.val();
                    const sessionData = {
                        buddyJoined: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
                        user: user || null,
                        buddy: {
                            id: user.id,
                            name: user.name ? user.name : "",
                            email: user.email ? user.email : "",
                        },
                        mirrorSessionStarted: datetime,
                        ...mirrorMetadata,
                        actions: [],
                        decisions: [],
                    };
                    setBuddySession(sessionId, sessionData);
                }
            });

            if (user) {
                updateRealTimeDbSession(hospitalId, deviceId, {
                    signedTo: user.name,
                    signedToId: user.id,
                });
            }

            const tempCall = snapshot.val();
            setCall(tempCall);

            if (tempCall === "DON") {
                setCallTypeName(t("call.donning"));
                //setCallTypeInstructions(t("call.donning_instructions"));
            } else {
                setCallTypeName(t("call.doffing"));
                //setCallTypeInstructions(t("call.doffing_instructions"));
            }

            changeBusyState(user.hospital, user.id, true);

            startViewer(videoTag.current, deviceId, onRemoteStreamReceived, callDataHandler, onWebRtcError);
        });

        return () => {
            console.log("Stopping viewer");
            stopViewer();
            clearInterval(onlinePingInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const startRecording = useCallback(() => {
        setMicRing(true);
        let timeout = setTimeout(() => setMicRing(false), 500);
        setMicStatus(!micStatus);

        addBuddySessionActions(sessionId, {
            occuredAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
            user: user || null,
            type: actionTypes.clickButton,
            button: t("call.talk_to_user"),
            action: !micStatus ? t("call.talk") : t("call.silence"),
        });

        return () => {
            clearTimeout(timeout);
            setMicRing(false);
        };
    }, [micStatus, sessionId, t, user]);

    const enableMirrorMic = useCallback(() => {
        setMicRing(true);
        let timeout = setTimeout(() => setMicRing(false), 500);

        addBuddySessionActions(sessionId, {
            occuredAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
            user: user || null,
            type: actionTypes.clickButton,
            button: t("call.hear_mirror_user"),
            action: mirrorMicMuted ? t("call.hear") : t("call.mute"),
        });

        setMirrorMicMuded(!mirrorMicMuted);
        return () => {
            clearTimeout(timeout);
            setMicRing(false);
        };
    }, [mirrorMicMuted, sessionId, t, user]);

    useEffect(() => {
        const viewer = getViewer();
        if (!viewer.localStream) {
            return;
        }
        viewer.localStream.getAudioTracks()[0].enabled = micStatus;
    }, [micStatus]);

    const handlePauseButton = useCallback(() => {
        addBuddySessionActions(sessionId, {
            occuredAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
            user: user || null,
            type: actionTypes.clickButton,
            button: t("call.pause"),
            action: t("call.pause"),
        });
        sendCommandToMirror("PAUSE", undefined, false);
    }, [sessionId, t, sendCommandToMirror, user]);

    const handleReloadButton = useCallback(() => {
        addBuddySessionActions(sessionId, {
            occuredAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
            user: user || null,
            type: actionTypes.clickButton,
            button: t("call.reload_video"),
            action: t("call.reload_video"),
        }).then(() => window.location.reload());
    }, [sessionId, t, user]);

    const handleStopCallButton = useCallback(() => {
        addBuddySessionActions(sessionId, {
            occuredAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
            user: user || null,
            type: actionTypes.clickButton,
            button: `${t("call.finish")} ${callTypeName}`,
            action: t("call.finish"),
        }).then(() => onStopCall());
    }, [sessionId, t, onStopCall, callTypeName, user]);

    return (
        <>
            <main className="angel">
                <header className="angel__header">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginRight: "24px",
                        }}
                    >
                        <Link to={"/dashboard"}>
                            <img className="angel__logo" src={logo} alt="logo" />
                            <span>{packageJson.version}</span>
                        </Link>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Tooltip title={t("call.pause_session_tooltip")} placement="left">
                                <button
                                    type="button"
                                    style={{ marginRight: "20px", padding: "28px" }}
                                    className="connection__button green"
                                    onClick={handlePauseButton}
                                >
                                    {t("call.pause")}
                                </button>
                            </Tooltip>
                            <button type="button" className="connection__button orange" onClick={handleReloadButton}>
                                {t("call.reload_video")}
                            </button>
                            <button
                                type="button"
                                style={{ marginLeft: "18px", marginRight: "9px" }}
                                className="connection__button red"
                                onClick={handleStopCallButton}
                            >
                                {`${t("call.finish")} ${callTypeName}`}
                            </button>
                        </div>
                    </div>
                </header>
                <article className="angel__video">
                    <div className="angel__video__wrapper">
                        <div className="p-1 text-center rounded bg-light shadow">
                            <h6 className="mb-1">
                                {connectionStatus}
                                <button
                                    type="button"
                                    style={{
                                        width: "40px",
                                        marginRight: "10px",
                                        float: "right",
                                        borderRadius: "15px",
                                        background: !micStatus ? "#E7AEAE" : "#B3DEAF",
                                    }}
                                    title={t("call.talk_to_user")}
                                    onClick={startRecording}
                                >
                                    <FontAwesomeIcon
                                        style={{ color: "4C5056 " }}
                                        id="icon"
                                        icon={!micStatus ? faMicrophoneSlash : faMicrophone}
                                    />
                                </button>
                                <button
                                    type="button"
                                    style={{
                                        width: "40px",
                                        marginRight: "10px",
                                        float: "right",
                                        borderRadius: "15px",
                                        background: mirrorMicMuted ? "#E7AEAE" : "#B3DEAF",
                                    }}
                                    title={t("call.hear_mirror_user")}
                                    onClick={enableMirrorMic}
                                >
                                    <FontAwesomeIcon
                                        style={{ color: "4C5056 " }}
                                        id="icon"
                                        icon={mirrorMicMuted ? faVolumeMute : faVolumeUp}
                                    />
                                </button>
                            </h6>
                        </div>
                        <div className="angel__video__div">
                            <video
                                id="mirrorVideo"
                                className={showModal ? "with_question" : "without_question"}
                                autoPlay
                                muted={mirrorMicMuted}
                                loop
                                ref={videoTag}
                            />
                        </div>
                        <div className="angel__video__feedback">
                            {audio ? (
                                <div className={`angel__video__feedback__flow ${title ? "active" : ""}`}>
                                    {currentProcedure}
                                    <FontAwesomeIcon style={{ color: "#B3DEAF" }} id="icon" icon={faVolumeUp} />
                                </div>
                            ) : (
                                <div className="angel__video__feedback__flow">
                                    {currentProcedure}
                                    <FontAwesomeIcon id="icon" icon={faVolumeMute} />
                                </div>
                            )}
                            <div className="angel__video__feedback__dash"></div>
                            {HH && counterTimeoutFunc ? (
                                <div className="angel__video__feedback__step">
                                    {currentStep} {counter}
                                </div>
                            ) : (
                                <div className={`angel__video__feedback__step ${audio && step ? "active" : ""}`}>
                                    {currentStep}
                                </div>
                            )}
                            {/* console.log(audioPlayerStatus) */}
                            {/* console.log(counterStatus) */}
                        </div>
                        {showModal && (
                            <div className="" style={{ display: "block" }}>
                                <div className="" role="document">
                                    <div className="modal-content rounded shadow border-0">
                                        <div className="modal-body" style={{ backgroundColor: "lightblue" }}>
                                            <div className="bg-white p-2 rounded box-shadow">
                                                <p className="text-muted mb-0 angel__confirmation_text">
                                                    {isAIRevert ? `${modalMessage}: YES` : modalMessage}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="modal-footer" style={{ backgroundColor: "lightblue" }}>
                                            {isAIRevert ? (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="ml-auto connection__button red"
                                                        style={{ marginRight: "10px" }}
                                                        onClick={() => {
                                                            executeModalInstruction("Revert", revertModalInstruction);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            style={{ color: "4C5056 ", marginRight: "10px" }}
                                                            id="icon"
                                                            icon={faReply}
                                                        />
                                                        {t("call.revert_decision")}
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="ml-auto connection__button red"
                                                        style={{ marginRight: "10px" }}
                                                        onClick={() => {
                                                            executeModalInstruction("No", modalNoInstruction);
                                                        }}
                                                    >
                                                        {t("call.no")}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mr-auto connection__button green"
                                                        style={{ marginLeft: "10px" }}
                                                        onClick={() => {
                                                            executeModalInstruction("Yes", modalYesInstruction);
                                                        }}
                                                    >
                                                        {`${t("call.yes")}`}
                                                    </button>
                                                </>
                                            )}
                                            {modalTimeout}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* 
            { remoteEvents &&
              orderBy(remoteEvents, ["datetime"], ["desc"]).map((event) => {
                const eventItems = event.data.split("#");
                const dateTime = eventItems[0];
                const feature = eventItems[1];
                const type = eventItems[2];
                const payload = eventItems[3];
                //console.log(eventItems);

                let alertType = "alert-secondary";

                if (payload === "ERROR")  alertType = "alert-danger";
                else if (payload === "FINISHED") alertType = "alert-success";
                else if (payload === "STARTED") alertType = "alert-primary";

                //console.log(alertType)
                //console.log(dateTime)
              })
            }
          */}
                </article>
                <article className="angel__flow disable-scrollbars">
                    {steps.map((step, index) => {
                        const commandId = step.procedure || step;
                        if (procedures[commandId]) {
                            return (
                                <Flow
                                    setCurrentStepHandler={(i) => flowHandler(i)}
                                    key={`${index}-${commandId}`}
                                    index={index}
                                    show={openedProcedure === index}
                                    procedure={procedures[commandId]}
                                    commandSender={sendCommandToMirror}
                                    mirrorSession={sessionId}
                                />
                            );
                        } else return "";
                    })}
                </article>
                <article className="angel__steps disable-scrollbars">
                    {steps.map((step, index) => {
                        const commandId = step.procedure || step;
                        if (procedures[commandId]) {
                            return (
                                <Steps
                                    sel={openedProcedure}
                                    key={`${index}-${commandId}`}
                                    index={index}
                                    opened={openedProcedure === index}
                                    procedure={procedures[commandId]}
                                    commandSender={sendCommandToMirror}
                                    currentStep={currentStep}
                                    mirrorSession={sessionId}
                                />
                            );
                        } else return "";
                    })}
                </article>
            </main>
            {(ring || micRing) && (
                <>
                    <ReactAudioPlayer src={ring ? ringAudio : micRing ? micAudio : ""} autoPlay loop />
                </>
            )}
        </>
    );
}

export default Call;
