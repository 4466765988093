import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const strings = require("./strings.json");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: strings,
    lng: "en-au",
    lowerCaseLng: true,
    fallbackLng: "en-au",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
