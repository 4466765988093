import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import PrivateRoute from "./PrivateRoute";
import Call from "./components/Call";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/call/:hospitalId/:deviceId/:sessionId" component={Call} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
