import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { firebaseAuth } from "./utils/firebase";
import CheckingUser from "./components/CheckingUser";
import EnvironmentStrip from "./utils/EnvironmentStrip";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isUserLoaded, setUserLoaded] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(function (user) {
      if (user) setAuthenticated(true);
      else setAuthenticated(false);
      if (!isUserLoaded) setUserLoaded(true);
    });

    return () => unsubscribe();
  }, [isUserLoaded]);

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          isUserLoaded ? (
            isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to="/" />
            )
          ) : (
            <CheckingUser />
          )
        }
      />
      <EnvironmentStrip />
    </>
  );
};

export default PrivateRoute;
