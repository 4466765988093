/*eslint-disable */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import alert from '../assets/audio/alert.mp3';
import ReactAudioPlayer from 'react-audio-player';
import moment from "moment-timezone";

import '../assets/css/angel.css' // Importing new styles
import { addBuddySessionActions } from "../../utils/firebaseUtils/firestoreWrites";

const nextStepList = (guide, instruction) => {
  let stepList = {};
  for (let i = 0; i < Object.keys(guide).length; i++) {
    if (!guide[instruction] || !guide[instruction].nextinstruction) {
      return stepList;
    }
    stepList[instruction] = guide[instruction];
    instruction = guide[instruction].aimodel ? guide[instruction].aimodel.nextinstruction : guide[instruction].nextinstruction;
  }
  return stepList;
} 

const sortGuide = (guide, first) => {
  let stepList = nextStepList(guide, first);
  let difference = Object.keys(guide).filter(x => !Object.keys(stepList).includes(x));
  if (difference) {
    difference.forEach(value => stepList[value] = guide[value]);
  }

  return stepList;
};

function Steps({ sel, index, opened, procedure, commandSender, currentStep, mirrorSession }) {

  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState();
  const [prompt, setPrompt] = useState(false)

  function clickHandler(command, index, key, procedure, stepName, action) {
    if (selected === key) return;

    const actionToInsert = {
        occuredAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
        type: 'SEND_COMMAND',
        button: `${procedure.title.name} -> ${stepName}`,
        action: action,
        details: {
          procedure: procedure,
          procedureId: procedure.id,
          type: command,
          stepId: key
        }
    };

    addBuddySessionActions(mirrorSession, actionToInsert);

    commandSender(`${command}|${index}|${procedure.id}|${key}`, undefined, true)
    setSelected(key)
  }

  useEffect(() => {
    setSelected(null)
  }, [sel])

  useEffect(() => {
    if(procedure.guide!==undefined) 
      if(procedure.guide.promptangel)  setPrompt(true)
  }, [procedure, currentStep])

  const handleClose = () => {
    setPrompt(false);
  };

  useEffect(()=>{
    map(procedure.guide, (value, key) => {
      if((value[i18n.language])===currentStep) setSelected(key)
    })
    map(procedure.error, (value, key) => {
      if((value[i18n.language])===currentStep) setSelected(key)
    })
    map(procedure.success, (value, key) => {
      if((value[i18n.language])===currentStep) setSelected(key)
    })
  },[currentStep])

  return (
    <>
      { opened && (
        <>
          {procedure.guide &&
            map(sortGuide(procedure.guide, 
              procedure.title.aimodel ? procedure.title.aimodel.nextinstruction : procedure.title.nextinstruction), 
              (value, key) => (
              <a key={key}
                className={`angel__steps__button blue ${(selected === key) ? "active" : ""}`}
                onClick={() => (selected === key) ? '' : clickHandler('GUIDE', index, key, procedure, value.name, t('call.send_guide'))}
              >
                {value.name}
              </a>
            ))
          }
          {procedure.error &&
            map(procedure.error, (value, key) => (
              <a
                key={key}
                className={`angel__steps__button red ${(selected === key) ? "active" : ""}`}
                onClick={() => (selected === key) ? '' : clickHandler('ERROR', index, key, procedure, value.name, t('call.send_error'))}
              >
                {value.name}
              </a>
            ))
          }
          {procedure.success &&
            map(procedure.success, (value, key) => (
              <a
                key={key}
                className={`angel__steps__button green ${(selected === key) ? "active" : ""}`}
                onClick={() => (selected === key) ? '' : clickHandler('SUCCESS', index, key, procedure, value.name, t('call.send_success'))}
              >
                {value.name}
              </a>
            ))
          }
          <div>
            <Dialog
              open={prompt}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"The next step needs attention!"}</DialogTitle>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {prompt &&
            <>  
              <ReactAudioPlayer src={alert} autoPlay/>
            </>
          }
        </>
      )}
    </>
  );
}

export default Steps;
